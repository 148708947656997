import {
  Button,
  Popconfirm,
  Table,
  Typography,
  notification,
  DatePicker,
  Tabs,
  Input,
  Space,
  Select,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
const { Search } = Input;
import CustomLabelValue from "pages/components/CustomLabelValue";
import {
  HistoryOutlined,
  UnorderedListOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import ImportDataPig from "pages/PigFarm/ListPigFarm/importDataPig";
import { ModalInfoType, PayloadType } from "type";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import _ from "lodash";
import queryString from "query-string";
import { memo, useEffect, useState } from "react";
import { getStatisticals } from "features/statisticalSlice";
const { Title } = Typography;
import CUContract from "./CUContract";
import { getContracts, setQuery } from "features/contractSlice";
import moment from "moment";
import { useSelector } from "react-redux";
import RContract from "./RContract";
function tabContent(data: any) {
  const dataHerd = _.get(data, "record", {});
  const typeOfHerd = _.get(data, "record.typeId.code", "");
  const { query, contracts } = useSelector(
    (state: any) => state.contractReducer
  );
  const dataTabList = [
    {
      label: "Danh sách lợn trong trại",
      key: "LIST",
    },
    {
      label: "Lịch sử",
      key: "HISTORY",
    },
  ];

  const dataTabListTTT = [
    {
      label: "Danh sách lợn trong trại",
      key: "LIST",
    },
  ];

  const handleChangeTabList = (key: string) => {
    setKeyTabList(key);
  };
  const [searchText, setSearchText] = useState<string>("");
  const [keyTabList, setKeyTabList] = useState<any>("LIST");
  const dataActiverHerd = _.get(data, "dataActiverHerd", {});
  const dispatch = useDispatch();

  const filterByYear = (e: any) => {
    const year =
      _.isNull(e) || _.isNaN(e) || _.isUndefined(e) ? "" : moment(e).year();
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        year: year, //Trường lọc data là name và
      })
    );
  };

  const handleSearch = () => {
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        searchValue: searchText,
        page: 1, //Trường lọc data là name và
      })
    );
  };

  const handleChangeFilterType = (value: string) => {
    dispatch(
      setQuery({
        ...query,
        populate: "servicePackageIds.farmScaleTTNId farmScaleTTTId, userId", // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        page: 1,  //Trường lọc data là name và
      })
    );
  };


  const filterByMonthAndYear = (e: any) => {
    const year =
      _.isNull(e) || _.isNaN(e) || _.isUndefined(e) ? "" : moment(e).year();
    const month =
      _.isNull(e) || _.isNaN(e) || _.isUndefined(e)
        ? ""
        : moment(e).month() + 1;
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        year: year,
        month: month, //Trường lọc data là name và
      })
    );
  };
  const columns: ColumnsType<any> = [
    {
      title: "Ngày đăng ký",
      dataIndex: "startDateContract",
      key: "startDateContract",
      width: 150,
      render: (val, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {moment(val).utc().format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: "Mã HĐ/Chủ trại",
      dataIndex: "code",
      key: "code",
      width: 150,
      render: (val, record) => {
        return (
          <div>
            <p>{val}</p>
            <b>{record?.userId.name}</b>
          </div>
        );
      },
    },
    {
      title: "Quy mô trại",
      dataIndex: "userId",
      key: "userId",
      width: 450,
      render: (val, record) => {
        return (
          <div>
            <p>
              <b>Nái: </b>
              {record?.servicePackageIds?.[0]?.farmScaleTTNId?.name ?? 0}
            </p>
            <p>
              {" "}
              <b>Thịt: </b>
              {record?.servicePackageIds?.[0]?.farmScaleTTTId?.name ?? 0}
            </p>
          </div>
        );
      },
    },
   
    {
      title: "Triển khai",
      dataIndex: "userId",
      key: "userId",
      width: 200,
      render: (val, record) => {
        return (
          <div>
            <p>
              {val?.regUserHerdBody?.typeStart === "working"
                ? "Trại đang hoạt động"
                : "Trại mới"}
            </p>
            <p>{val?.regUserHerdBody?.monitoring.map((item:any) => {
                if (item === "excel") {
                  return "File excel";
                } else if (item === "note") {
                  return "Sổ ghi chép";
                } else {
                  return "Hình thức khác";
                }
              }).join(", ")}</p>
          </div>
        );
      },
    },
    {
      title: "Gói thuê bao",
      dataIndex: "servicePackageIds",
      key: "servicePackageIds",
      width: 200,
      render: (val, record) => {
        return <>{val.map((item: any) => item.name).join(", ")}</>;
      },
    },
    {
      title: "Hạn dùng thử",
      dataIndex: "endDateContract",
      key: "endDateContract",
      width: 150,
      render: (val, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {moment(val).utc().format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (val) => {
        if (val === "doing") {
          return <div style={{ color: "#00B578" }}>Đăng kí mới</div>;
        } else {
          return <div style={{ color: "red" }}>Đã hỗ trợ</div>;
        }
      },
    },
  ];

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Select
            showSearch
            style={{ width: "200px" }}
            placeholder="Trạng thái"
            options={[
              {
                label: "Đăng kí mới",
                value: "trial",
              },
              {
                label: "Đã hỗ trợ",
                value: "doing",
              },
            ]}
            allowClear
            onChange={handleChangeFilterType}
            // onSearch={handleSearchFilterType}
            optionFilterProp="children"
            // filterOption={(input, option) =>
            //   (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            // }
          />
          <DatePicker
            picker="year"
            placeholder="Chọn năm HĐ"
            onChange={(e) => filterByYear(e)}
          />
          <DatePicker
            picker="month"
            placeholder="Chọn tháng HĐ"
            onChange={(e) => filterByMonthAndYear(e)}
          />
          <Search
            placeholder="Tìm theo tất cả"
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={handleSearch}
            style={{ width: 242 }}
          />
          {data.activeKey === "1" ?  <Button
            type="primary"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Thêm mới hợp đồng khách hàng",
                content: <CUContract idUpdate={undefined} getContractByStatuss={data.getContractByStatuss}    />,
                size: 550,
              };
              dispatch(setModal(payload));
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button> : ""} 
          
        </Space>
      </div>{" "}
      <Table
        columns={columns}
        rowKey={"id"}
        onRow={(record: any) => ({
          onClick: () => {
            const payload: ModalInfoType = {
              open: true,
              title: "Chi tiết hợp đồng khách hàng",
              content: <RContract record={record} activeKey={data?.activeKey}   />,
              size: 1400,
            };
            dispatch(setModal(payload));
          },
        })}
        dataSource={_.get(contracts, "results", [])}
        pagination={{
          total: _.get(contracts, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
    </>
  );
}

export default memo(tabContent);
