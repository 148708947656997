import { useEffect, useState } from "react";
import {
  Button,
  Popconfirm,
  Table,
  Typography,
  Space,
  notification,
  Modal,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { ModalInfoType, PayloadType, AdminDataType } from "type";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import CUUser from "pages/AdminUser/ListUser/CUUser";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getUsers, setQuery, updateUser } from "features/userSlice";
import queryString from "query-string";
import { getRoleByName } from "features/roleSlice";
import moment from "moment";
import CUContract from "./CUContract";
const { Title } = Typography;

const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;

function Service(dataView: any) {
  console.log("dataView", dataView?.data)
  const dispatch = useDispatch();
  const [dataRecord, setDataRecord] = useState<any>( {});
  const { query } = useSelector((state: any) => state.userReducer);
  const { role } = useSelector((state: any) => state.roleReducer);
  const [isChildModalVisible, setIsChildModalVisible] = useState(false);
  const handleUpdateDataRecord = (updatedData:any) => {
    setDataRecord(updatedData);
  };
  useEffect(()=> {
    setDataRecord(dataView?.data);
  }, [dataView])
  // Lấy danh sách tài khoản
  function handleGetUsers() {
    if (!role || _.get(role, "name", "") !== "admin") {
      const payload: PayloadType = {
        params: "admin",
        callback: {
          success() {
            dispatch(
              setQuery({
                ...query,
                populate: "roleId",
              })
            );
          },
          failed() {
            notification.error({
              message: "Không thể cập nhật thông tin người dùng!",
              description: "Thiếu quyền!",
            });
          },
        },
      };
      dispatch(getRoleByName(payload));
      return;
    }
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getUsers(payload));
  }

  useEffect(() => {
    handleGetUsers();
  }, [query]);

  const columns: ColumnsType<AdminDataType> = [
    {
      title: "Tên gói",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ngày đăng ký",
      dataIndex: "startDateContract",
      key: "startDateContract",
      render: (val, record: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            {moment(record?.startDateContract).utc().format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: "Số tháng",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Thời hạn",
      dataIndex: "endDateContract",
      key: "endDateContract",
      align: "left",
      render: (val, record: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            {moment(record?.endDateContract).utc().format("DD/MM/YYYY")}
          </div>
        );
      },
    },
  ];

  const showChildModal = () => {
    setIsChildModalVisible(true);
  };

  const handleChildModalCancel = () => {
    setIsChildModalVisible(false);
  };

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Button
            type="primary"
            onClick={showChildModal}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.get(dataRecord, "servicePackageIds", [])}
        rowClassName={(record: any) =>
          record?.status === "active" ? "table-row-open" : "table-row-close"
        }
        pagination={{
          total: dataRecord?.servicePackageIds?.length,
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
      {/* Modal con */}
      <Modal
        title="Thêm mới gói dịch vụ"
        visible={isChildModalVisible}
        onCancel={handleChildModalCancel}
        footer={null} // Nếu cần footer, có thể tuỳ chỉnh lại
        width={700}
      >
        <CUContract dataRecord={dataRecord} onClose={handleChildModalCancel}  onUpdate={handleUpdateDataRecord} activeKey={dataView?.activeKey}   />
      </Modal>
    </>
  );
}

export default Service;
